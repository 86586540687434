form {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

input[type=text], input[type=email], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
}

input[type=text]:focus-visible, input[type=email]:focus-visible {
    outline: 2.5px solid #385bf5;
}

input[type=submit] {
    width: 100%;
    background-color: #385bf5;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.5em;
}

input[type=submit]:hover {
    background-color: #3351d5;
}

/* Mobile */
@media only screen and (max-width: 1200px) {
    form {
        width: 90%;
    }
}