@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.App {
  text-align: center;
  font-family: sans-serif;
}

/* Images */
.logo {
  max-height: 40vh;
}
/* Mobile */
@media only screen and (max-width: 900px) {
  .logo {
    max-height: 35vh;
  }
}


/* Fonts */

/* Headings */
h2 {
  font-family: 'Sen', sans-serif;
  font-size: 2.5em;
  color: #465a65;
}

h3 {
  font-family: 'Sen', sans-serif;
  color: #465a65;
  font-size: 2em;
  margin: 1em 0 0;
}

h4 {
  font-family: 'Sen', sans-serif;
  color: #465a65;
  font-size: 2.2em;
  margin: 1em 0 0;
}

.desc {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3em;
}

.p1 {
  font-size: 1.2em;
  margin-top: 0.75em;
  margin-bottom: 0;
}
/* Mobile */
@media only screen and (max-width: 1200px) {
  .desc {
    max-width: 90%;
  }
}


