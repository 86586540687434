/* Base */
.displayBox{
    margin-top: 2em;
}

h5 {
    font-family: 'Sen', sans-serif;
    color: #465a65;
    font-size: 1.4em;
    margin: 0 0 5px;
}
h6 {
    font-family: 'Sen', sans-serif;
    color: #465a65;
    font-size: 2em;
    margin-bottom: 0.5em;
    margin-top: 2em;
}
h4 {
    margin: 0;
    color: #385bf5;
    font-size: 3em;
}


/* Buttons */
button {
    margin: 1em;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1.2em;
    padding: 0.75em 1.5em;
    cursor: pointer;
}

.buttonBox button {
    margin: 1em;
    border: none;
    color: white;
    font-size: 1.5em;
    padding: 1em 2em;
    cursor: pointer;
}

 .yesButton {
    background-color: #40da4c;
}

.grayButton {
    background-color: #454945;
}

.noButton {
    background-color: #e74040;
}

.half-grid .yesButton {
    background-color: #385bf5;
}

.half-grid .noButton {
    background-color: #40da4c;

}
.finalList {
    text-align: center;
}

.finalList p {
    font-size: 1.5em;
    font-weight: 500;
    margin: 0.75em;
}

/* Mobile */
@media only screen and (max-width: 950px) {
    .buttonBox button {
        margin: 2em 1em 1em;
        padding: 1em 1.75em;
        font-size: 1.4em;
    }
}
/* Stage 2 */
.half-grid {
    display: grid;
    grid-template-columns: auto auto;
}
.half-grid ul {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.half-grid h3 {
    margin-top: 0;
}
.wordItem {
    display: flex;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
}
.wordItem p {
    font-size: 1.3em;
    margin: 0;
}
.wordItem button {
    margin: 0.5em 1em 0 0;
    padding: 0.5em;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.paddedBox {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 750px) {
    .half-grid ul {
        width: 90%;
        padding-left: 0;
    }
    .wordItem p {
        font-size: 1.1em;
    }
    .wordItem button {
        margin: 0.25em 0.5em 0 0;
        border-radius: 50%;
        height: 40px;
        width: 40px;
    }
    .half-grid h3 {
        font-size: 1.2em;
    }

    .paddedBox {
        width: 90%;
    }
}
